.manage-activity-container {
    margin: 20px;
}

.manage-activity-header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
}

.manage-activity-button {
    background-color: #28a745;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
}

.manage-activity-button:hover {
    background-color: #218838;
}

.manage-firmling-list {
    list-style: none;
    padding: 0;
    max-height: 600px; /* Setze eine feste Höhe für die Listen */
    overflow-y: auto; /* Ermöglicht vertikales Scrollen */
}

.manage-activity-list {
    padding-left: 0;

 }

.manage-activity-list-scroll {
    list-style: none;
    padding: 0;
    max-height: 300px; /* Setze eine feste Höhe für die Listen */
    overflow-y: auto; /* Ermöglicht vertikales Scrollen */
}


 /*Liste Firmstunden und Liste Firmsonntage */

.manage-activity-list-item {
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    background-color: rgba(255, 255, 255, 0.8);
}

.manage-activity-list-item strong {
    flex-basis: 25%;      /* Basisbreite für den Namen */
    flex-grow: 2;         /* Priorität, um mehr Platz zu bekommen */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.manage-activity-list-item p {
    flex-basis: 50%;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.manage-activity-list-item .kalender-week-info {
    flex-basis: 5%;      /* Basisbreite für KW */
    flex-grow: 1;         /* Geringere Priorität, um Platz zu bekommen */
    text-align: center;
}

.manage-activity-actions {
    flex-basis: 20%;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}

.manage-activity-actions {
    display: flex;
    gap: 10px;
}

.manage-activity-edit-button, .manage-activity-delete-button {
    background-color: #17a2b8;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.manage-activity-edit-button:hover {
    background-color: #138496;
}

.manage-activity-delete-button {
    background-color: #dc3545;
}

.manage-activity-delete-button:hover {
    background-color: #c82333;
}

.manage-activity-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
}

.manage-activity-modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.manage-activity-modal-header {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.manage-activity-modal-input, .manage-activity-modal-textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.manage-activity-modal-save-button, .manage-activity-modal-cancel-button {
    padding: 8px 12px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
}

.manage-activity-modal-save-button {
    background-color: #28a745;
    color: white;
}

.manage-activity-modal-save-button:hover {
    background-color: #218838;
}

.manage-activity-modal-cancel-button {
    background-color: #6c757d;
    color: white;
    margin-left: 8px;
}

.manage-activity-modal-cancel-button:hover {
    background-color: #5a6268;
}

/* Globale Anpassungen für kleinere Bildschirme */
@media (max-width: 768px) {
    .manage-activity-container {
        margin: 10px;
    }
    .manage-activity-list-scroll {
        max-height: 200px; /* Setze eine feste Höhe für die Listen */

    }

    .scroll-big {
        max-height: 500px
    }


    .manage-activity-header {
        font-size: 20px;
    }

    .manage-activity-button {
        padding: 6px 10px;
        font-size: 14px;
    }

    .manage-activity-list-item {
        flex-direction: column;
        align-items: flex-start;
        padding: 8px;
        margin: 5px 0;
    }

    .manage-activity-list-item strong {
        flex-basis: auto;
        font-size: 16px;
    }

    .manage-activity-list-item p {
        flex-basis: auto;
        font-size: 14px;
    }

    .manage-activity-list-item .kalender-week-info {
        flex-basis: auto;
        text-align: left;
        font-size: 14px;
    }

    .manage-activity-actions {
        flex-basis: auto;
        width: 100%;
        justify-content: flex-start;
    }

    .manage-activity-edit-button, .manage-activity-delete-button {
        padding: 5px 8px;
        font-size: 14px;
    }

    .manage-activity-modal-content {
        width: 90%;
        max-width: 100%;
        padding: 15px;
    }

    .manage-activity-modal-header {
        font-size: 18px;
    }

    .manage-activity-modal-input, .manage-activity-modal-textarea {
        padding: 6px;
        font-size: 14px;
    }

    .manage-activity-modal-save-button, .manage-activity-modal-cancel-button {
        padding: 6px 10px;
        font-size: 14px;
    }
}

.manage-user-modal ul {
    list-style-type: none;
    padding: 0;
}

.manage-user-modal ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.manage-user-modal ul li button {
    margin-left: 10px;
    background-color: #f44336;
    color: white;
    border: none;
    cursor: pointer;
}

.manage-user-modal input {
    margin-bottom: 10px;
}

.manage-user-modal button {
    margin-top: 10px;
}
