/* HomePage.css */
.home-page-container {
    margin: 20px;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #ffcc00;
    border-radius: 5px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    text-align: center;
}

.home-page-container h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
    color: #ffffff;
}

.home-page-container p {
    font-size: 1.2em;
    line-height: 1.6;
    color: #ffcc00;
}
