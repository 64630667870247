.login-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Volle Höhe des Viewports */
}
.login-container {
    width: 420px;
    max-width: 420px;
    padding: 16px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 28px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    animation: fadeIn 1s ease-in-out;
    overflow-y: auto;
    max-height: 90vh;
}

.form-group {
    margin-bottom: 20px;
}
.form-control {
    background: rgba(255, 255, 255, 0.8);
    color: #333;
    border: 2px solid #7d7d7d;
    border-radius: 10px;
    height: 45px;
}
.btn-primary {
    background-color: #128225;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
}
.btn-primary:hover {
    background-color: #147322;
}
.error-message {
    color: #ffcc00;
    font-weight: 700;
    margin-top: 20px;
}
.toggle-password {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
}
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
