.acksmiContent{
    justify-content: left;
    font-family: 'Roboto', sans-serif;
    color: white;
}
.acksmiboard {
    display: flex;

    justify-content: left;
}
.firm-list {
    width: 307px;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 5px;
    margin: 5px;
    height: 800px;
    overflow-y: scroll;

}
.firm-list h3 {
    text-align: center;
    font-size: 1.2em;
    margin-bottom: 10px;
    color: #ffcc00;
}
.card {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    font-size: 0.9em;
    height: 175px;
    overflow: auto;
    color: #333;

}

.wuensche {
    font-size: 0.8em;
    text-align: left;
    padding-bottom: 0;
}
.titlename {
    font-size: 0.8em;
}
.timeslot-table th, .timeslot-table td {
    border: 1px solid #ccc;
    text-align: center;
    font-size: 0.15em;
    line-height: 1;
    color: #333;
    padding: 2px; /* weniger Abstand */
    width: 40px; /* Spaltenbreite reduzieren */
}

/* Größere Schrift für die oberste Reihe (Header) */
.timeslot-table th {
    font-size: 0.6em; /* größere Schriftgröße für Header */
    font-weight: bold;
}

/* Größere Schrift für die linkeste Spalte */
.timeslot-table td:first-child {
    font-size: 0.56em; /* größere Schriftgröße für die erste Spalte */
    font-weight: bold;
}


/* Maximalhöhe für Zeilen ohne die Tabellenstruktur zu zerstören */
.timeslot-table td, .timeslot-table th {
    height: 10px; /* Standardhöhe der Zellen */
    max-height: 20px; /* maximale Höhe */
    overflow: hidden; /* überschüssigen Inhalt ausblenden */
    text-overflow: ellipsis; /* zeigt "..." für abgeschnittenen Text */
}

/* Optional: Die Tabellenzellen zentrieren */
.timeslot-table td, .timeslot-table th {
    text-align: center;
    vertical-align: middle;
}


.timeslot.selected {
    background-color: rgba(70, 194, 27, 0.42);
    color: white;
}
.container2 {

    max-width: 100%;
}

.mobile-message {
    display: none;
    text-align: center;
    font-size: 1.2em;
    margin: 20px;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 5px;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    body {
        transform: scale(0.8);
        transform-origin: 0 0;
    }
    .firm-list {
        padding: 3px;
        margin: 3px;
    }

}
@media (max-width: 768px) {
    .acksmiboard, .container2 {
        display: none;
    }
    .mobile-message {
        display: block;
    }
}



/* Scrollbar anpassen */
.firm-list::-webkit-scrollbar {
    width: 8px; /* Breite der Scrollbar */
}

.firm-list::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.3); /* Hintergrund der Scrollspur */
    border-radius: 5px; /* Runde Ecken */
}

.firm-list::-webkit-scrollbar-thumb {
    background-color: #ffcc00; /* Farbe des Scrollbalkens */
    border-radius: 5px; /* Runde Ecken für den Scrollbalken */
    border: 2px solid transparent; /* Abstand zwischen Scrollbalken und Spur */
    background-clip: padding-box; /* Clip für bessere Abgrenzung */
}

.firm-list::-webkit-scrollbar-thumb:hover {
    background-color: #ffcc33; /* Farbe des Scrollbalkens beim Hover */
}
