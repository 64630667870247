.manage-user-container {
    margin: 20px;
}

.manage-user-header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
}

.manage-user-button {
    background-color: #007bff;
    color: white;
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
}

.manage-user-button:hover {
    background-color: #0056b3;
}

.manage-user-list {
    list-style: none;
    padding: 0;
    max-height: 600px;
    overflow-y: auto;
}

.manage-user-list-item {
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    background-color: #f8f9fa;
}

.manage-user-list-item strong {
    flex-basis: 25%;
    flex-grow: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.manage-user-list-item p {
    flex-basis: 50%;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.manage-user-actions {
    flex-basis: 20%;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}

.manage-user-edit-button, .manage-user-delete-button {
    background-color: #17a2b8;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.manage-user-edit-button:hover {
    background-color: #138496;
}

.manage-user-delete-button {
    background-color: #dc3545;
}

.manage-user-delete-button:hover {
    background-color: #c82333;
}

.manage-user-lock-button {
    background-color: #f44336;
    color: white;
    margin-left: 10px;
}

.manage-user-unlock-button {
    background-color: #4CAF50;
    color: white;
    margin-left: 10px;
}

.manage-user-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
}

.manage-user-modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.manage-user-modal-header {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.manage-user-modal-input, .manage-user-modal-textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.manage-user-modal-save-button, .manage-user-modal-cancel-button {
    padding: 8px 12px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
}

.manage-user-modal-save-button {
    background-color: #28a745;
    color: white;
}

.manage-user-modal-save-button:hover {
    background-color: #218838;
}

.manage-user-modal-cancel-button {
    background-color: #6c757d;
    color: white;
    margin-left: 8px;
}

.manage-user-modal-cancel-button:hover {
    background-color: #5a6268;
}

.manage-user-role-list {
    list-style: none;
    padding: 0;
    margin-top: 10px;
}

.manage-user-role-item {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
}

.manage-user-role-item button {
    background-color: #f44336;
    color: white;
    border: none;
    cursor: pointer;
    padding: 4px 6px;
    border-radius: 3px;
    margin-left: 5px;
}

.manage-user-role-input {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: calc(100% - 20px);
}

/* Globale Anpassungen für kleinere Bildschirme */
@media (max-width: 768px) {
    .manage-user-container {
        margin: 10px;
    }

    .manage-user-header {
        font-size: 20px;
    }

    .manage-user-button {
        padding: 6px 10px;
        font-size: 14px;
    }

    .manage-user-list {
        max-height: 500px;
    }

    .manage-user-list-item {
        flex-direction: column;
        align-items: flex-start;
        padding: 8px;
        margin: 5px 0;
        overflow: hidden;
    }

    .manage-user-list-item strong, .manage-user-list-item p {
        flex-basis: auto;
        font-size: 16px;
    }

    .manage-user-actions {
        flex-basis: auto;
        width: 100%;
        justify-content: flex-start;
    }

    .manage-user-edit-button, .manage-user-delete-button, .manage-user-lock-button, .manage-user-unlock-button {
        padding: 5px 8px;
        font-size: 14px;
    }

    .manage-user-modal-content {
        width: 90%;
        max-width: 100%;
        padding: 15px;
    }

    .manage-user-modal-header {
        font-size: 18px;
    }

    .manage-user-modal-input, .manage-user-modal-textarea {
        padding: 6px;
        font-size: 14px;
    }

    .manage-user-modal-save-button, .manage-user-modal-cancel-button {
        padding: 6px 10px;
        font-size: 14px;
    }
}
