.managef-container {
    margin: 20px;
}

.firmling-list {
    list-style: none;
    padding: 0;
}

.firmling-item {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333;
}

.manage-firmling-list-scroll {
    list-style: none;
    padding: 0;
    max-height: 60vh; /* Setze eine feste Höhe für die Listen */
    overflow-y: auto; /* Ermöglicht vertikales Scrollen */
}

.firmling-item .actions {
    display: flex;
    gap: 10px;
}

.btn-edit, .btn-save {
    background-color: #17a2b8;
    border: none;
    color: white;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
}

.btn-edit:hover, .btn-save:hover {
    background-color: #138496;
}

.btn-delete {
    background-color: #dc3545;
    border: none;
    color: white;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
}

.btn-delete:hover {
    background-color: #c82333;
}

.btn-firmdetails {
    background-color: #28a745;
    border: none;
    color: white;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
}

.btn-firmdetails:hover {
    background-color: #218838;
}

.form-inline input, .form-inline select, .form-inline textarea {
    margin: 5px 0;
}

/* Modal styling */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    max-height: 90vh;
}

.modal-content h2 {
    margin-top: 0;
    color: #333;
    font-size: 1.8em;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

/* Form styling adjustments for closer alignment */
.modal-content form {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Reduced gap for closer alignment */
}

.form-group-edit {
    display: flex;
    flex-direction: column;
}

.form-group-edit label {
    font-weight: bold;
    color: #333; /* Ensures label visibility */
    margin-bottom: 4px;
}

.form-group-edit input,
.form-group-edit select,
.form-group-edit textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1em;
}

textarea {
    resize: vertical;
}

/* Table styling */
.time-slot-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.time-slot-table th,
.time-slot-table td {
    padding: 8px;
    text-align: center;
    border: 1px solid #ddd;
}

.time-slot-table th {
    background-color: #f9f9f9;
    font-weight: bold;
}

.time-slot-table input[type="checkbox"] {
    transform: scale(1.2);
    cursor: pointer;
}

.loading {
    font-size: 1.5em;
    color: #555;
    text-align: center;
    padding: 20px;
}

/*Anstatt checkboxen*/
.manage-activity-list-item {
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    cursor: pointer;
    background-color: #f8f9fa;
    transition: background-color 0.3s;
}

.manage-activity-list-item:hover {
    background-color: #e9ecef;
}

.manage-activity-list-item.completed {
    background-color: #d4edda;
    color: #155724;
}


/* Modal Styling */
.manage-activity-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.manage-activity-modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    width: 90%; /* Ensure responsiveness */
    max-height: 80vh; /* Limit the height to 80% of viewport */
    overflow-y: auto; /* Enables scrolling within the modal content */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
}

.manage-activity-header {
    font-size: 1.5em;
    margin-bottom: 1em;
}

.scroll-big {
    max-height: 600px
}

/* Ensure the list of activities has spacing and is scrollable */
