.register-wrapper {
    align-items: center;
    justify-content: center;
    display: flex;
}


.register-container {
    padding: 40px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    overflow-y: auto;
    max-height: 90vh;
    margin-top: 20px;
    max-width: 95%;
    width: 90%;
}


.form-group-register {
    margin-bottom: 40px;
}

.form-group-register label {
    color: #ffffff;
    font-weight: 700;
    font-size: 20px;
}

.form-control {
    background: rgba(255, 255, 255, 0.8);
    color: #333;
}

.form-check-label {
    color: #ffcc00;
}

.table {
    color: white;
}

.modal-content {
    background: rgba(0, 0, 0, 0.9);
    color: #c56060;
}

.btn-primary {
    background-color: #ffcc00;
    border: none;
}

.btn-primary:hover {
    background-color: #e6b800;
}

.btn-secondary {
    background-color: #48fd97;
    border: none;
}

.btn-secondary:hover {
    background-color: #209a5f;
}

.floating-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #48fd97;
    color: white;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s, transform 0.2s;
}

.floating-btn:hover {
    background-color: #209a5f;
}

.floating-btn:active {
    transform: scale(0.95);
}

.btn-group-toggle .btn {
    background-color: rgba(255, 255, 255, 0.8);
    color: #333;
    border: 1px solid #ddd;
    margin: 5px;
}

.btn-group-toggle .btn:hover, .btn-group-toggle .btn:focus, .btn-group-toggle .btn:active, .btn-group-toggle .btn.active {
    background-color: #ffcc00;
    color: #fff;
    border-color: #ffcc00;
}

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: rgb(208, 177, 55);
    border-color: #4e555b;
}

.register-table thead th:first-child {
    width: 150px; /* Passe die Breite an, z. B. auf 150px */
}

.register-table td:first-child {
    width: 150px; /* Passe die Breite der ersten Spalte an */
}

.register-table {
    width: 100%;
}

.register-timeslot {
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    margin: 0;
    transition: background-color 0.3s, transform 0.2s;
}

.register-timeslot::before {
    content: '';
    display: inline-block;
    font-size: 20px;
    color: black;
}

.register-timeslot.selected {
    background-color: rgba(70, 194, 27, 0.42);
    color: white;
}

.register-timeslot.selected::before {
    content: '';
}

.register-timeslot:active {
    transform: scale(0.95);
}

.registrationForm label {
    color: white;
}

.tes123t {
    color: white;
}

.navbar {
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    padding: 10px 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: space-between;
    align-items: center;
}


@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Styles for mobile view */
@media (max-width: 768px) {
    .container {
        display: none;
    }

    #mobileMessage {
        display: block;
        font-size: 1.2em;
        padding: 20px;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 15px;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    }
}

/* Styles for larger view */
@media (min-width: 769px) {
    #mobileMessage {
        display: none;
    }
}
