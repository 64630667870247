.navbar {
    width: 100%;
    max-width: 100%;
    background: rgba(0, 0, 0, 0.8);
    padding: 10px 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.nav-left, .nav-right {
    display: flex;
    align-items: center;
}

.nav-left a {
    color: #ffcc00;
    font-weight: 700;
    text-decoration: none;
    margin: 0 15px;
}

.navbar a:hover {
    text-decoration: underline;
}

/* Anpassung für mobile Ansicht */
@media (max-width: 768px) {
    .nav-left {
        display: none;
        flex-direction: column;
        background-color: rgba(0, 0, 0, 0.9);
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        padding: 10px;
    }

    .navbar.expanded .nav-left {
        display: flex;
    }

    .hamburger {
        display: block;
        cursor: pointer;
        font-size: 1.5rem;
        color: #ffcc00;
    }

    .nav-right {
        margin-left: auto;
    }
}
